<template>
  <!-- 提供说明解法 -->
  <div class="cson-bg">
    <div class="perfer">
      <div class="container">
        <div class="title">提供解法说明</div>
<!--        <div class="description">-->
<!--          提供解法说明，即可免费获得LeetCode视频讲解一天观看券（观看题数范围根据提供说明数决定）-->
<!--        </div>-->
        <div class="codeArea">
          <el-tabs v-model="activeTab" type="card">
            <el-tab-pane label="Java" name="0" v-if="answer.javaCode">
              <div class="javaCode" v-highlight>
                <pre>
                    <code style="margin-top:-15px;" v-text="answer.javaCode"></code>
                  </pre>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Python" name="1" v-if="answer.pythonCode">
              <div class="pythonCode" v-highlight>
                <pre>
                    <code style="margin-top:-15px;" v-text="answer.pythonCode"></code>
                  </pre>
              </div>
            </el-tab-pane>
            <el-tab-pane label="C++" name="2" v-if="answer.cplusPlusCode">
              <div class="cplusPlusCode" v-highlight>
                <pre>
                    <code style="margin-top:-15px;" v-text="answer.cplusPlusCode"></code>
                  </pre>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <el-form ref="form" :model="form" label-position="top" :rules="rules">
          <el-form-item label="解法说明：" prop="idea">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 20 }"
              v-model="form.idea"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="padding: 20px; text-align: center">
          <el-button
            type="primary"
            style="width: 130px"
            size="mini"
            @click="publishResolution"
            >保存发表</el-button
          >
        </div>
      </div>
      <div class="sideadvertisement">
        <SideAdvertisement></SideAdvertisement>
      </div>
    </div>
  </div>
</template>
<script>
import SideAdvertisement from "@/components/sideAdvertisement";

import { mapState } from "vuex";
import { publishResolution, getCodeResolution } from "@/service/leetcode";
export default {
  name: "perferIdea",
  components: { SideAdvertisement },
  data() {
    return {
      codeInfo: {},
      form: {},
      answer: {},
      rules: {
        idea: [
          {
            required: true,
            message: "请输入解法说明！",
            trigger: "blur",
          },
        ],
      },
      activeTab: "0",
    };
  },
  methods: {
    publishResolution() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          publishResolution({
            ...this.form,
            leetcodeProblemId: this.$route.query.problemId,
            answerId: this.$route.query.answerId,
          }).then((res) => {
            if (res.success) {
              this.$message.success("发布成功，请等待审核！");
              this.$router.go(-1);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    getCodeResolution() {
      getCodeResolution(this.$route.query.answerId).then((res) => {
        if (res.success) {
          this.answer = res.result;
        }
      });
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.getCodeResolution();
  },
};
</script>
<style lang="scss" scoped>
.cson-bg {
  background-color: #f4f4f4;
}
.perfer {
  padding: 20px 0;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  .container {
    width: 880px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 50px;
    .title {
      position: relative;
      font-weight: 500;
      color: #34495e;
      font-size: 16px;
      padding-left: 14px;
      line-height: 24px;
      padding-bottom: 12px;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 24px;
        background: #0a7aff;
        border-radius: 2px;
        left: 0;
        top: 0;
      }
    }
    .description {
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 18px;
    }
  }
  .sideadvertisement {
    width: 300px;
  }
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}
.codeArea {
  margin: 20px 0;
}
</style>